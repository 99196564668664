import { useState, useEffect } from "react"

// Cookie names
const ANALYTICS_CONSENT_COOKIE = "analytics_storage"
const ADS_CONSENT_COOKIE = "ad_storage"
const PERSONALIZATION_CONSENT_COOKIE = "ad_personalization"
const USER_DATA_CONSENT_COOKIE = "ad_user_data"

// Set a cookie with expiration
const setCookie = (name, value, days = 365) => {
  if (typeof document === "undefined") return
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  document.cookie = `${name}=${value}; ${expires}; path=/; SameSite=Lax`
}

// Get a cookie value by name
const getCookie = name => {
  if (typeof document === "undefined") return null
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(";").shift()
  return null
}

// Update Google Consent Mode state
const updateConsentState = (granted = true) => {
  if (typeof window !== "undefined" && window.gtag && window.dataLayer) {
    // Ensure dataLayer is initialized
    window.dataLayer = window.dataLayer || []

    function gtag(){dataLayer.push(arguments)}

    gtag("consent", "update", {
      ad_user_data: "granted",
      ad_personalization: "granted",
      ad_storage: "granted",
      analytics_storage: "granted",
    })
  }
}

// Custom hook to manage consent
const useConsent = () => {
  const [hasConsent, setHasConsent] = useState({
    analytics: false,
    ads: false,
    personalization: false,
    userData: false,
  })

  // Check for existing consent on mount
  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag && window.dataLayer) {
      const analyticsConsent = getCookie(ANALYTICS_CONSENT_COOKIE) === "granted"
      const adsConsent = getCookie(ADS_CONSENT_COOKIE) === "granted"
      const personalizationConsent =
        getCookie(PERSONALIZATION_CONSENT_COOKIE) === "granted"
      const userDataConsent = getCookie(USER_DATA_CONSENT_COOKIE) === "granted"

      const consentState = {
        analytics: analyticsConsent,
        ads: adsConsent,
        personalization: personalizationConsent,
        userData: userDataConsent,
      }

      setHasConsent(consentState)

      // Update gtag consent state based on stored cookies
      if (
        analyticsConsent ||
        adsConsent ||
        personalizationConsent ||
        userDataConsent
      ) {
        updateConsentState(true)
      }
    }
  }, [])

  const acceptConsent = () => {
    // Set all cookies
    setCookie(ANALYTICS_CONSENT_COOKIE, "granted")
    setCookie(ADS_CONSENT_COOKIE, "granted")
    setCookie(PERSONALIZATION_CONSENT_COOKIE, "granted")
    setCookie(USER_DATA_CONSENT_COOKIE, "granted")

    // Update local state
    setHasConsent({
      analytics: true,
      ads: true,
      personalization: true,
      userData: true,
    })

    // Update Google consent state
    updateConsentState(true)
  }

  return {
    hasConsent,
    acceptConsent,
  }
}

export default useConsent
