import React from 'react';
import { Transition } from '@headlessui/react';
import useConsent from '../hooks/useConsent';

const ConsentBanner = () => {
  const { hasConsent, acceptConsent } = useConsent();
  
  // Don't show the banner if consent has already been granted
  if (hasConsent.analytics || hasConsent.ads) {
    return null;
  }

  return (
    <Transition
      show={true}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="fixed bottom-0 left-0 right-0 z-50"
    >
      <div className="bg-white shadow-md border-t border-gray-100 p-4 md:px-6 md:py-4 flex flex-col md:flex-row items-start md:items-center justify-between">
        <p className="text-sm text-gray-700 mb-3 md:mb-0">
          We use cookies to improve your experience and for marketing. Read our{' '}
          <a href="/privacy" className="text-pink-600 hover:text-amber-800 font-medium">
            cookie policy
          </a>.
        </p>
        <button
          onClick={acceptConsent}
          className="px-6 py-2 bg-pink-600 hover:bg-pink-700 text-white text-sm font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 whitespace-nowrap"
        >
          Accept
        </button>
      </div>
    </Transition>
  );
};

export default ConsentBanner; 