import React from 'react'
import BoardLogo from '../BoardLogo'

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="w-12 h-12 animate-pulse">
        <BoardLogo />
      </div>
    </div>
  )
}

export default LoadingScreen 